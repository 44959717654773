import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button, Container } from '@material-ui/core';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import Ribbon from '../../components/Ribbon';
import CardWide from '../../components/Cards/CardWide';
import Maze from '../../components/Maze';
import { plafondafwerking } from '../../lib/overviewData';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "palsie/pals5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "palsie/pals5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "palsie/pals1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages1: file(relativePath: { eq: "palsie/pals2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages2: file(relativePath: { eq: "palsie/pals4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gridImages3: file(relativePath: { eq: "palsie/pals5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const PlafondafwerkingOverview = ({ data, path, location }) => {
  const seo = {
    title: `Overzicht plafondafwerking - M.Pals Stukadoors`,
    description: `Laat uw plafondafwerking verzorgen met professionaliteit en geniet optimaal van uw huis.`,
    pathname: path,
    location,
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const GridImages = [data.gridImages1, data.gridImages2, data.gridImages3];

  const ribbon = {
    image: sources,
    heading: `Plafondafwerking overzicht`,
    pitchLine: `Laat uw plafondafwerking verzorgen met professionaliteit en geniet optimaal van uw huis.`,
  };

  const textTop = {
    header: `Diverse diciplines van Plafondafwerking van M.Pals Stukadoors.`,
    textWithLink: () => (
      <>
        <p>
          Niet zeker wat voor soort Plafondafwerking u wilt in uw nieuwbouw of
          bestaande woning. In de sectie hieronder vindt u betreft
          Plafondafwerking. Nog geen keuze kunnen maken? Neem dan gerust contact
          met ons op.
        </p>
      </>
    ),
  };
  const textBottom = {
    header: `Even persoonlijk contact?`,
    textWithLink: () => (
      <>
        <p>
          Nog vragen of wilt u een afspraak maken? Dan proberen we samen uw
          problemen op te lossen.
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0629575549'>
            <Button
              role='button'
              variant='contained'
              className='btn heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            role='button'
            variant='contained'
            className='btn heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </>
    ),
  };

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        location={location}
      />
      <Ribbon
        fluid={ribbon.image}
        heading={ribbon.heading}
        pitchLine={ribbon.pitchLine}
      />
      <Container fixed className='mt-10x mb-10x'>
        <CardWide
          className='center-xs'
          headerElem='h2'
          headerTitle={textTop.header}
          data-sal='slide-left'
        >
          <>
            {textTop.inner &&
              textTop.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textTop.textWithLink && textTop.textWithLink()}
          </>
          <></>
        </CardWide>
        <Maze data={plafondafwerking} path={path} gridImages={GridImages} />
        <CardWide
          className='center-xs mt-10x'
          headerElem='h2'
          headerTitle={textBottom.header}
          data-sal='slide-bottom'
        >
          <>
            {textBottom.inner &&
              textBottom.inner.map((text, i) => <p key={i}>{text}</p>)}
            {textBottom.textWithLink && textBottom.textWithLink()}
          </>
          <></>
        </CardWide>
      </Container>
    </Layout>
  );
};

PlafondafwerkingOverview.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default PlafondafwerkingOverview;
